.pvr-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pvr-top.pvr-share-wrap,
.similar-items-container {
    padding: 25px 15px 0;
    max-width: calc(100% - 88px);
    margin: 0 auto;
}

.pvr-top-right {
    display: flex;
    align-items: center;
    column-gap: 25px;
}

.LotsAuction .pvr-top-right .printViewBtn {
    cursor: pointer;
}

.pv-lot-box {
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 38px;
    padding: 10px 18px;
    line-height: 16px;
    font-size: 16px;
    letter-spacing: 0.3px;
}

.product-view-container {
    display: flex;
    padding: 20px 15px;
    max-width: calc(100% - 88px);
    margin: 0 auto;
}

.product-view-left {
    width: 40%;
}

.product-view-right {
    width: 60%;
    padding-left: 44px;
}

.close-img {
    cursor: pointer;
    padding: 5px;
    position: fixed;
    z-index: 99;
    top: 2px;
}

.searchExt {
    width: 40px;
    height: 40px;
    border-radius: 25px;
    background: #dbdbdb;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #222;
    margin-right: auto;
    margin-left: 20px;
}

.checkRotate {
    height: 500px;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
}

.hoverZoom img {
    height: 500px !important;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100% !important;
}

.fullscreen .hoverZoom img {
    height: 100vh !important;
    -o-object-fit: contain;
    object-fit: contain;
}

.lotNo {
    font-size: 15px;
    font-weight: 500;
    color: #000000;
}

.LotsAuction .lotNo {
    font-size: 18px !important;
    font-weight: 700 !important;
}

/* MULTISELLER PRODUCT DRAWER */

.multisellerProductDrawer .mpTop {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
}

.multisellerProductDrawer .mpTop .plTitle {
    font-size: 24px;
    color: #2e2e2e;
    font-weight: 600;
    margin: 0;
}

.multisellerProductDrawer .mpTop .plTitle span:first-child {
    font-size: 15px;
    margin-bottom: 8px;
    color: #747474;
    font-weight: 500;
    display: block;
}

.multisellerProductDrawer .mssDesc h6 {
    font-weight: 600;
    font-size: 16px;
    color: #333;
}

.multisellerProductDrawer .mssDesc p {
    font-weight: 400;
    font-size: 16px;
    color: #333;
}

.beachLiquidation .mssDesc p {
    height: 100%;
}

.multisellerProductDrawer .close-img {
    left: 0;
    position: absolute;
    top: 10px;
}

.multisellerProductDrawer .mssBidItem {
    margin-top: 20px;
}

.prodInfoSlider .pv-con span {
    font-weight: 600 !important;
    display: inline-block;
    padding-right: 5px;
}

.multisellerProductDrawer .mssBidItem .mssBidInfo h5 {
    font-size: 18px;
    font-weight: 400;
    color: #333;
    margin: 0;
}

.itemPurchased {
    width: 100%;
    text-align: center;
    padding: 10px;
    background: #ffe6c2;
    color: #9c6e2d;
    font-size: 18px;
    margin-top: 20px;
}

/* .multisellerProductDrawer .mssBidItem .mssBidInfo h5:first-child {
    margin-bottom: 15px;
} */

.multisellerProductDrawer .mssBidItem .mssBidInfo h5 span {
    font-weight: 600;
    color: #333;
    margin-right: 10px;
    display: inline-block;
}

.multisellerProductDrawer .mssBidItem .mssBidInfo h5:last-child {
    color: #373737;
}

.multisellerProductDrawer .sl-pbids.slidView {
    width: 480px;
}

.multisellerProductDrawer .sl-pbids.slidView.notLogged {
    width: 100%;
}

.multisellerProductDrawer .mssTimer {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px;
    text-align: center;
    border: 1px dashed #ccc;
    letter-spacing: 1px;
    color: #555;
}

.multisellerProductDrawer .sl-pbids.slidView button {
    width: 100%;
    margin: initial;
}

.multisellerProductDrawer .sl-pbids.slidView .customInput {
    margin-bottom: 15px;
}

.multisellerProductDrawer .sl-pbids.slidView .bidDet:first-child {
    margin-bottom: 15px;
}

.multisellerProductDrawer .slidView .biddingCnt .primButton {
    margin-right: 0;
}

.multisellerProductDrawer .slidView .biddingCnt > div {
    width: 100%;
}

.newLife .multisellerProductDrawer .slidView .biddingCnt .bid-wrapper > div {
    width: 49%;
}

.liquidationTwo.primeBids .slidView .biddingCnt .bid-wrapper > div {
    width: 100%;
}

.multisellerProductDrawer .sl-pbids.slidView .secButton button,
.sl-pbids.slidView .secButton button {
    width: 100%;
    height: 40px;
}

.multisellerProductDrawer .sl-pbids .secButton {
    margin: 10px 0;
}

.multisellerProductDrawer .mssBidHistory {
    color: var(--primColor);
    text-transform: initial;
    margin: 0;
    font-weight: 600;
}

.multisellerProductDrawer .mssBidMisc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.multisellerProductDrawer .mssBidIntDet .mssBidMisc {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.multisellerProductDrawer .mssBidIntDet .multisellertwo {
    display: block;
}

.multisellerProductDrawer .mssBidIntDet .multisellertwo .mssBidInfo:first-child {
    margin-bottom: 15px;
}

.multisellerProductDrawer .mssBidIntDet .mssBidMisc .mssBidInfo:not(:last-child) {
    margin-right: 25px;
}

.multisellerProductDrawer .mssBidHistory .material-icons {
    margin-right: 10px;
}

.multisellerProductDrawer .mssBidHistory .MuiButton-label {
    font-weight: 600;
}

.multisellerProductDrawer .MuiAccordion-root .MuiAccordionSummary-root {
    margin-bottom: 0;
}

.multisellerProductDrawer .MuiAccordion-root .accTitle {
    margin-bottom: 0;
}

.multisellerProductDrawer .multiSellerTimerView h6 {
    font-size: 22px;
    font-weight: 500;
}

.multisellerProductDrawer .multiSellerTimerView h6 span {
    color: #070707;
}

.multisellerProductDrawer .timerWrapper .timeLabel::before {
    left: 5%;
}

.multisellerProductDrawer .timerWrapper .timeLabel::after {
    right: 5%;
}

.productViewDrawer .bidStatus {
    padding-top: 60px;
}

.productViewDrawer .MuiDrawer-paper .productStatus {
    position: absolute;
    top: 10px;
    width: 100%;
    left: 0;
    max-width: 670px;
}

.productViewDrawer .MuiDrawer-paper .productStatus.hwaiPosiChange {
    position: absolute;
    top: auto;
    bottom: -35px;
    margin-bottom: 18px;
    left: 0;
    padding-left: 0;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.productViewDrawer .productStatus h4 {
    text-align: center;
    height: auto;
    width: 100%;
    display: flex;
    padding: 10px;
    width: auto;
    position: relative;
    z-index: 100;
}

.rightDrawer .loadingCnt {
    display: block;
}

.sliderLoader {
    padding: 15px;
}

.LotsAuction .sliderLoader .loadingCnt {
    grid-template-columns: 1fr;
}

.sliderLoader .productViewSkeleton .pvsImage,
.sliderLoader .productViewSkeleton .pvsDetails {
    width: 100%;
}

.sliderLoader .productViewSkeleton .skeletonWrapper {
    padding: 0;
    margin: 10px;
    box-shadow: none;
}

.pvsBottom > header {
    background-color: #f5f5f5;
    box-shadow: none;
    border-radius: 45px;
    margin: 45px 0 15px 0;
}

.pvsBottom .MuiTabs-root .MuiTab-root {
    height: 45px;
    min-height: initial;
    border-radius: 45px;
    opacity: 1;
}

.pvsBottom .MuiTab-root .MuiTab-wrapper {
    flex-direction: row;
}

.pvsBottom .MuiTab-root .MuiTab-wrapper .material-icons {
    padding-right: 8px;
}

.pvsBottom .MuiTabs-root .MuiTab-root.Mui-selected {
    background: var(--primColor);
}

.pvsBottom .MuiTab-root.Mui-selected .MuiTab-wrapper {
    color: #fff;
}

.pvsBottom .MuiTabs-root .MuiTabs-indicator {
    display: none;
}

.pvsBottom .MuiTabs-root .MuiTabs-flexContainer {
    border-bottom: none;
    width: 100%;
    max-width: initial;
    display: flex !important;
}

.productPageAct {
    width: 100%;
    color: var(--secColor);
    border: 1px solid var(--secColor);
    margin-bottom: 15px;
}

.prodInfoSlider .image-gallery-left-nav,
.prodInfoSlider .image-gallery-right-nav {
    width: 35px;
    height: 35px;
    background: #fff;
    padding: 0;
    border: 1px solid #ddd;
    -webkit-filter: none;
    filter: none;
    border-radius: 50%;
}

.prodInfoSlider .image-gallery-left-nav:hover .image-gallery-svg,
.prodInfoSlider .image-gallery-right-nav:hover .image-gallery-svg {
    transform: none;
    color: #737373;
}

.prodInfoSlider .image-gallery-left-nav:hover,
.prodInfoSlider .image-gallery-right-nav:hover {
    background: #ddd;
}

.prodInfoSlider .image-gallery-left-nav .image-gallery-svg,
.prodInfoSlider .image-gallery-right-nav .image-gallery-svg {
    width: inherit;
    height: inherit;
    color: #c5c5c5;
}

.howBidLink {
    color: var(--primColor);
    cursor: pointer;
}

.rotateImage {
    width: -webkit-max-content !important;
    width: -moz-max-content !important;
    width: max-content !important;
    min-width: -webkit-max-content !important;
    min-width: -moz-max-content !important;
    min-width: max-content !important;
    position: absolute !important;
    bottom: 0px;
    right: 40px;
    color: #5c5c5c !important;
    background: #cccccca1;
    z-index: 10;
    height: 40px;
    width: 40px !important;
}

.rotateImage .material-icons {
    font-size: 32px;
}

.image-gallery-fullscreen-button {
    padding: 0 !important;
    height: 40px;
    width: 40px;
}

.image-gallery-fullscreen-button .image-gallery-svg {
    color: #5c5c5c !important;
}

.image-gallery-fullscreen-button .image-gallery-icon {
    -webkit-filter: none !important;
    filter: none !important;
}

.cpyToClipDiv {
    width: 31px;
    height: 31px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    background: #4fa7ac;
    justify-content: space-around;
    border-radius: 100px;
}

.cpyToClipDiv .material-icons {
    font-size: 18px;
    color: white;
}

/*ART AUCTOIN*/

.tooling {
    color: #000 !important;
    background-color: #e1e1e1 !important;
    border-radius: 50% !important;
    height: 20px;
    width: 20px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
    min-width: unset !important;
    margin-right: 4px;
}

/* SLIDE POPUP */

.spCnt {
    display: grid;
    grid-template-columns: 400px auto;
    gap: 20px;
}

.spCnt .image-gallery {
    width: 100%;
}

.slidePopup .spMisc {
    display: flex;
    justify-content: space-between;
}

.slidePopup .lotNo {
    text-align: right;
}

.slidePopup .lotNo span {
    margin-right: 8px;
    font-size: 13px;
    font-weight: 600;
    color: #242424;
}

.slidePopup .pvMiscLabel {
    text-align: right;
    font-size: 13px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 10px;
}

.slidePopup .pvShare button:not(:last-child) {
    margin-right: 8px;
}

.slidePopup .mssBidInfo h5,
.slidePopup .mssBidInfo .timerCnt {
    font-size: 13px;
    font-weight: 600;
    color: #242424;
    margin-bottom: 10px;
    display: block;
}

.slidePopup .mssBidInfo h5 span,
.slidePopup .mssBidInfo .timerCnt span {
    width: 150px;
    color: #242424;
    display: inline-block;
}

.slidePopup .mssBidInfo .timerCnt {
    color: #d31616;
}

.slidePopup .mssDesc h6 {
    font-size: 14px;
    font-weight: 600;
    color: var(--secColor);
    margin-bottom: 5px;
}

.slidePopup .mssDesc div * {
    font-size: 13px;
    font-weight: 600;
    color: #242424;
}

.slidePopup .image-gallery-thumbnail,
.slidePopup .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 50px;
    width: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border: none !important;
}

.slidePopup .image-gallery-thumbnail:hover {
    opacity: 0.75;
}

.more-details {
    border: none;
    background-color: transparent;
    color: #2e70b0;
}

.more-details:hover {
    text-decoration: underline;
}

.slidePopup .image-gallery-thumbnail:hover,
.slidePopup .image-gallery-thumbnail:focus,
.slidePopup .image-gallery-thumbnail.active {
    border: none;
    opacity: 1;
}

.slidePopup .image-gallery-slide-wrapper,
.slidePopup .image-gallery-slides,
.slidePopup .image-gallery-slide,
.slidePopup .image-gallery-slide img {
    height: 300px;
}

.slidePopup .spLt .favoriteCheck {
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100px;
    width: 45px;
}

.slidePopup .closeBtn {
    position: absolute;
    top: 0px;
    right: 5px;
    min-width: unset;
    border-radius: 100px;
    width: 40px;
    height: 40px;
}

.slidePopup .sliderLoader .loadingCnt {
    display: block;
}

.slidePopup .sliderLoader .loadingCnt .productViewSkeleton > div {
    display: grid;
    grid-template-columns: 400px auto;
    gap: 20px;
    justify-content: normal !important;
}

.pv-comments-wrapper {
    margin: 50px;
    max-width: 825px;
}

.property-head {
    font-size: 18px;
    color: #000;
    line-height: 24.58px;
}

.property-head .how-comments-work {
    font-size: 14px;
    color: #000;
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
}

.vr-link {
    margin-top: 12px;
    display: inline-block;
    font-size: 14px;
    line-height: 19.12px;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
}

.comment-form-box {
    border: none;
    padding: 0;
    background-color: #fff;
    margin-top: 12px;
    height: 154px;
}

.comment-form-box .form-control {
    height: 98px;
    background-color: #f2f2f2;
    font-size: 14px;
    line-height: 20px;
}

.pv-comments-wrapper .form-group {
    margin-bottom: 1rem;
}

.pv-comments-wrapper .pc-descp {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #595959;
    margin: 12px 0 0;
}

.pv-comments-wrapper .name-comment {
    display: grid;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    grid-template-columns: 46% auto;
}

.pv-comments-wrapper .name-comment .dbc-text {
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 21px;
    color: #000;
}

.comment-user-name {
    display: inline-block;
    max-width: calc(100%-14px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
}

.commentsCardDate {
    font-size: 12px;
    line-height: 19px;
    text-align: right;
    color: #9faebb;
}

.register-to-comment {
    cursor: pointer;
    width: 155px;
    text-transform: capitalize;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    white-space: wrap;
}

.bid-box {
    padding: 12px 15px;
    margin-top: 12px;

    border-radius: 0.25rem;
    font-weight: 600;
}

.bid-box.highBidder {
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}

.bid-box.highBidder .text-right {
    font-size: 15px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #000;
}

.pv-comments-wrapper .comments-card {
    border: none;
    border-bottom: 2px solid #e0e0e0;
    margin-top: 15px;
}

.register-to-comment.btn-border {
    background-color: #fff;
    border-color: #000;
    color: #000;
    line-height: 18px;
    padding: 10px;
    display: block;
    float: right;
}

.bottomDrawer .productStatus {
    padding: 20px 0;
    height: 30px;
    border-radius: 30px;
    margin: 0;
    /* padding-left: 30px; */
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    min-width: 118px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 8px;
}

.bottomDrawer h5.mssBidHistory {
    color: var(--secColor);
    font-size: 13px;
    font-weight: 500;
}

.bottomDrawer .pvsBottom > header {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    margin: 45px 0 15px 0;
}

.bottomDrawer .pvsBottom .MuiTabs-root .MuiTab-root {
    background: whitesmoke;
    border-radius: 0px;
}

.bottomDrawer .pvsBottom .MuiTabs-root .MuiTab-root:not(:last-child) {
    border-right: 3px solid white;
}

.bottomDrawer .pvsBottom .MuiTabs-root .MuiTab-root.Mui-selected {
    background: #9ea2a2;
    color: white;
}

.bottomDrawer .pvsBottom .tabBody {
    border: 1px solid #eaeaea;
    padding: 10px;
    height: 100%;
}

.bottomDrawer .bynwBtn.primButton button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background: var(--secColor);
    border: 1px solid var(--secColor);
}

.bottomDrawer .byorBdBtn.primButton button {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.bottomDrawer .bynwBtn.primButton button.Mui-disabled {
    color: rgb(185, 185, 185);
}

.bottomDrawer .sl-pbids.slidView {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 21px;
    column-gap: 21px;
    max-width: 830px;
    width: 100%;
}

.shwMrBtnWrp {
    margin-bottom: 20px;
}

.pdtDscrpnVlue ul,
.pdtDscrpnVlue ol,
.pdtDscrpnVlue dl {
    padding-left: 20px;
}

.pdtDscrpnVlue.hide p {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pdtDscrpnVlue p {
    height: 100%;
    overflow: visible;
    white-space: normal;
}

.multisellerProductDrawer .shwMrBtn {
    white-space: pre;
    margin-left: 30px;
    align-self: flex-end;
}

.LotsAuction .makeMyOffer .MuiInputLabel-outlined {
    margin-top: -3px;
}

.LotsAuction .makeMyOffer .MuiInputLabel-outlined.Mui-focused {
    margin-top: 0px;
}

.LotsAuction .makeMyOffer .MuiInputBase-input {
    padding: 15.5px 14px;
}

.productViewStandalone .multiAuctionProductView .productInfo.minBdInfo h5 {
    margin-bottom: 0;
}

.productViewStandalone .multiAuctionProductView .productInfo h5 {
    font-size: 15px;
}

.productViewStandalone .multiAuctionProductView .productInfo h5 span {
    width: 135px;
}

.imgGlrWrpr {
    position: relative;
    display: block;
}

.imgGlrWrpr .soldWrapper {
    background: #00000096;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 59px;
    color: #ffffffa3;
    font-weight: bold;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-flex;
}

.imgGlrWrpr .soldWrapper p {
    margin: 0;
    transform: rotate(324deg);
}

.dscrpnCtnrArt img {
    width: 100%;
}

.dscrpnCtnrArt ol,
.dscrpnCtnrArt ul,
.dscrpnCtnrArt dl {
    padding-left: 30px;
}

.msSliderInner .vdoPlyrWrapper > div {
    width: 100% !important;
}

.prodInfoSlider .additionalDesc h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.prodInfoSlider .additionalDesc p {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 700;
    color: #4a4a4a;
}

.prodInfoSlider .additionalDesc p span {
    font-weight: 400;
    color: #333;
}

.prodInfoSlider.zoomOnHover .image-gallery-slides {
    display: none;
}

.text-Yellow {
    color: #ffcc00;
}

.prodInfoSlider .galleryPrice.rtlrPrice {
    color: #333;
    margin-top: 15px;
}

.pvTabViewBtnGrp {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.pvTabViewBtnGrp .MuiButton-root.active {
    background: #fff;
    color: #000;
    border-bottom: 1px solid #000;
}

.pvTabViewBtnGrp .MuiButton-root {
    border-radius: 0;
    background: #ededed;
    margin-right: 5px;
    border-bottom: 1px solid #ededed;
}

/* Responsiveness */

.slidePopup .image-gallery-content.fullscreen .image-gallery-slide-wrapper,
.slidePopup .image-gallery-content.fullscreen .image-gallery-slides,
.slidePopup .image-gallery-content.fullscreen .image-gallery-slide,
.slidePopup .image-gallery-content.fullscreen .image-gallery-slide img {
    height: 100%;
    min-height: 93vh;
}

.hide {
    width: 100%;
    line-height: 1.2em;
    height: 3.6em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.productViewStandalone .productStatus {
    width: 100%;
    max-width: unset;
    padding: 0;
}

.vehicleProduct .pvsRight .vehicleDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px;
    background: whitesmoke;
    margin-bottom: 30px;
}

.vehicleProduct .pvsRight .vehicleDetails ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0px;
}

.vehicleProduct .pvsRight .vehicleDetails ul li {
    display: flex;
    align-items: center;
}

.vehicleProduct .pvsRight .vehicleDetails ul li:not(:last-child) {
    margin-bottom: 3px;
}

.vehicleProduct .pvsRight .vehicleDetails ul li label {
    margin: 0;
    color: gray;
    font-weight: 500;
    background: #ececec;
    padding: 10px;
    min-width: 40%;
    margin-right: 10px;
}

.vehicleProduct .pvsRight .vehicleDetails ul li span {
    font-weight: 500;
    text-transform: capitalize;
}

.vehicleProduct .bdngWrapper {
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 25px;
}

.vehicleProduct .bdngWrapper .dtInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.vehicleProduct .bdngWrapper .dtInfo h5 {
    background: whitesmoke;
    padding: 10px 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vehicleProduct .bdngWrapper .dtInfo h5 span {
    padding-right: 15px;
    color: gray;
}

.vehicleProduct .bdngWrapper .dtInfo h5 span.material-icons {
    padding-right: 5px;
    font-size: 20px;
}

.productViewStandalone .vehicleProduct .productInfo h5 {
    height: 40px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.vehicleProduct .lotChip {
    padding: 6px 15px;
    font-size: 14px;
    background: whitesmoke;
    border-radius: 100px;
    margin-top: 10px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.productViewStandalone .vehicleProduct .mpTop {
    align-items: flex-end;
}

.descriptionContainer.textHide {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.descriptionContainer ul,
.descriptionContainer ol,
.descriptionContainer dl {
    padding-left: 30px;
}

.dscrptnWthScrl {
    max-height: 150px;
    overflow: auto;
    font-size: 13px;
    font-weight: 500;
}

.vehicleProduct .phtSwpr .pswp-thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 7px;
}

.vehicleProduct .phtSwpr .pswp-thumbnails .pswp-thumbnail img {
    width: 100%;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
}

.vehicleProduct .phtSwpr .pswp__img {
    -o-object-fit: contain;
    object-fit: contain;
}

.vehicleProduct p.cntBdOwn {
    background: #ffdcdc;
    padding: 15px 20px;
    margin: 0;
    border-radius: 10px;
    color: red;
    text-align: center;
    font-weight: 500;
    width: 100%;
}

@media print {
    .product-view-left,
    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-left .image-gallery-thumbnails {
        overflow: hidden;
    }
}

@media (max-width: 767px) {
    .Liquidation.hawaii .productViewDrawer .MuiDrawer-paper .productStatus.hwaiPosiChange {
        top: 45px;
        bottom: auto;
        right: 0;
        left: auto;
    }

    .prodInfoSlider {
        margin-top: -20px;
    }

    .LiquidationThree .checkRotate {
        height: fit-content;
        /* max-height: 325px; */
    }
}

@media (max-width: 600px) {
    .Fundraiser .multisellerProductDrawer .slidView .biddingCnt > div {
        width: auto;
    }

    .multisellerProductDrawer .shwMrBtn {
        margin-left: 0;
    }
}

@media (max-width: 500px) {
    .myWrapping .pdtDscrpnVlue,
    .myWrapping .bullet-list-wrapper {
        max-width: 390px;
        width: 100%;
    }

    .LiquidationThree .image-gallery-content .image-gallery-swipe .checkRotate {
        max-height: 325px;
    }

    .LiquidationThree .image-gallery-content.fullscreen .image-gallery-swipe .checkRotate {
        max-height: unset;
    }
}

@media (max-width: 425px) {
    .pvMiscActions button:not(:first-child) {
        margin-left: 6px !important;
    }

    .MultiSeller_two .pvMiscActions {
        flex-wrap: wrap;
    }
}

.pvsRight .favoriteCheck {
    border-radius: 10px;
    height: 30px;
}

.BidAuction .pvsRight .favoriteCheck {
    height: auto;
    background-color: transparent;
    float: unset;
    margin: 0 !important;
    width: 100%;
    justify-content: center !important;
    margin-top: 40px !important;
    margin-bottom: 65px !important;
}

.BidAuction .pvsRight .favoriteCheck .favBtnStyle {
    border: 1px solid #013748;
    background: #013748;
    border-radius: 16px;
    width: fit-content;
    padding: 15px 25px;
    height: auto;
}

.product-view-left .image-gallery-thumbnail {
    outline: none;
    border: 1px solid #e6e6e6;
    width: 70px;
    height: 70px;
    border-radius: 12px;
    margin-bottom: 15px;
}

.product-view-left .image-gallery-thumbnail.active,
.product-view-left .image-gallery-thumbnail:focus,
.product-view-left .image-gallery-thumbnail:hover {
    border: 3px solid #444389;
    box-shadow: 0 4px 4px 0 #dddada;
}

.product-view-left .image-gallery-thumbnail img {
    max-width: calc(70px - 4px);
    max-height: calc(70px - 4px);
    object-fit: contain;
}

.product-view-left .image-gallery-slide img {
    border: 0.5px solid #d6d4d4;
    border-radius: 20px;
}

.LotsAuction .product-view-right .productStatus {
    width: 100%;
    padding: 0 0 10px;
    position: relative;
    margin: 0;
    padding: 0;
    height: auto;
}

.LotsAuction .product-view-right .productStatus h4 {
    margin: 0 0 20px;
}

.LotsAuction .product-view-right .productStatus h4.winning,
.LotsAuction .product-view-right .productStatus h4.won {
    background: #5ec26a !important;
    background: linear-gradient(
        90deg,
        #fff 0%,
        #5ec26a 15%,
        #5ec26a 50%,
        #5ec26a 85%,
        #fff 100%
    ) !important;
    width: 100%;
    padding: 5px 10%;
    height: auto;
    min-height: 30px;
}

.LotsAuction .product-view-right .productStatus h4.outbid,
.LotsAuction .product-view-right .productStatus h4.losing,
.LotsAuction .product-view-right .productStatus h4.lost {
    background: #f20505 !important;
    background: linear-gradient(
        90deg,
        #fff 0%,
        #f20505 15%,
        #f20505 50%,
        #f20505 85%,
        #fff 100%
    ) !important;
    width: 100%;
    padding: 5px 10%;
    height: auto;
    min-height: 30px;
}

.LotsAuction .product-view-right .plTitle {
    font-weight: 600;
    font-size: 28px;
    text-align: left;
    color: #333;
    margin: 5px 0;
    line-height: 1.25;
    text-transform: capitalize;
}

.pv-qrating,
.pv-rating-wrap .timer-wrapper {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3px;
    column-gap: 10px;
    color: #000;
}

.pv-rating-wrap .timer-wrapper .timerCnt > div {
    display: flex;
}

.pv-rating-wrap .lv-timer {
    margin: 0 auto;
    width: 24px;
}

.pv-rating-wrap .timer-wrapper {
    color: #db3e3e;
}

.pv-rating-wrap {
    margin: 14px auto;
}

.pv-bid-details {
    display: flex;
}

.pv-bid-details > .pv-con {
    padding: 0 28px;
    min-height: 64px;
    line-height: 26px;
}

.pv-bid-details > .pv-con:first-child {
    padding: 0 28px 0 0;
}

.pv-bid-details > .pv-con + .pv-con {
    border-left: 1px solid #ddd;
}

.bids-wrap {
    display: flex;
    column-gap: 10px;
}

.pv-bids-details {
    display: flex;
    column-gap: 2px;
}

.bids-row .sl-pbids.slidView {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 22px 0;
}

.bids-row .sl-pbids.slidView > form,
.bids-row .sl-pbids.slidView > .quick-bid {
    width: 100%;
}

.pl-media {
    display: flex;
    column-gap: 15px;
    margin: 16px auto;
    padding: 15px 20px;
    background: #f2f2f2;
    border-radius: 6px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.3px;
    font-weight: 400;
}

.pl-media h5 {
    font-weight: 700;
}

.bids-row .slidView .biddingCnt .customInput fieldset {
    border-radius: 5px !important;
}

.LotsAuction .bids-row .customInput .MuiOutlinedInput-adornedEnd {
    margin-right: 15px;
}

.LotsAuction .bids-row .sl-pbids.slidView button {
    width: 100%;
    border-radius: 20px;
    height: 40px;
}

.LotsAuction .product-view-wrapper .MuiTab-textColorInherit {
    letter-spacing: 0.3px;
    opacity: 1;
    font-weight: 600;
    color: #333;
    border-bottom: 3px solid transparent;
    line-height: 25px;
}

.LotsAuction .product-view-wrapper .MuiTabs-flexContainer {
    width: 100%;
    border-bottom: 0.59px solid #ccc;
}

.LotsAuction .product-view-wrapper .MuiTab-textColorInherit.Mui-selected {
    opacity: 1;
    background: transparent;
    border-color: var(--secColor);
}

.LotsAuction .product-view-wrapper .pv-tabpanel {
    padding: 36px 0;
}

.LotsAuction .product-view-wrapper .customInput {
    margin-bottom: 16px;
}

.similar-items-head {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.02px;
    line-height: 30px;
    margin-bottom: 25px;
}

.pvr-top-right .favoriteCheck {
    background: transparent;
    float: none;
}

.pvr-top-right .fav-wrap,
.pvr-top-right > a {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.LotsAuction .share-menu-popup button.MuiButton-root.copyClipboard,
.LotsAuction .share-menu-popup button {
    padding: 6px 0;
    text-transform: capitalize;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.LotsAuction .share-menu-popup button.MuiButton-root.copyClipboard span.share-text,
.LotsAuction .share-menu-popup button span.share-text {
    min-width: 72px;
}

.LotsAuction .share-menu-popup button span.tw-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LotsAuction .share-menu button,
.LotsAuction .pvr-top-right a,
.LotsAuction .pvr-top-right .fav-wrap {
    text-transform: capitalize;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    color: #232323;
    font-weight: 400;
}

.LotsAuction .share-menu-popup button.MuiButton-root.copyClipboard .material-icons {
    width: 32px;
}

@media (min-width: 768px) and (max-width: 992px) {
    .LotsAuction .pvr-top.pvr-share-wrap,
    .LotsAuction .similar-items-container,
    .LotsAuction .product-view-container {
        width: 100%;
        max-width: 100%;
    }

    .LotsAuction .product-view-left {
        width: 350px;
    }

    .LotsAuction .product-view-right {
        width: calc(100% - 350px);
        padding-left: 20px;
    }

    .LotsAuction .product-view-left .image-gallery-slide img {
        height: 350px;
    }

    .LotsAuction .product-view-right .plTitle {
        line-height: 1.35;
        font-size: 20px;
    }

    .LotsAuction .product-view-right .pv-rating-wrap {
        margin: 14px auto;
        flex-wrap: wrap;
    }

    .LotsAuction .pv-bid-details {
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .LotsAuction .pv-con,
    .pv-bid-details > .pv-con:first-child {
        padding: 0 10px;
    }

    .LotsAuction .product-view-wrapper .MuiTabs-flexContainer {
        overflow: scroll;
    }
}

@media (max-width: 767px) {
    .LotsAuction .product-view-right .timer-wrapper {
        background: transparent;
        padding: 0;
        margin: 10px 0;
    }

    .LotsAuction .pv-bid-details {
        flex-wrap: wrap;
        margin: 0 -10px;
    }

    .LotsAuction .pv-con,
    .pv-bid-details > .pv-con:first-child {
        padding: 0 10px;
    }

    .pv-bid-details > .pv-con + .pv-con {
        border-left: none;
    }

    .LotsAuction .product-view-left,
    .LotsAuction .product-view-right {
        width: 100%;
        padding: 0;
    }

    .LotsAuction .pvr-top.pvr-share-wrap,
    .LotsAuction .similar-items-container,
    .LotsAuction .product-view-container {
        max-width: 100%;
    }

    .LotsAuction .product-view-container {
        flex-wrap: wrap;
    }

    .LotsAuction .product-view-left .image-gallery-slide img {
        height: 280px;
    }

    .LotsAuction .product-view-left .image-gallery {
        max-width: 350px;
        margin: 0 auto;
    }

    .LotsAuction .product-view-right .plTitle {
        font-size: 22px;
        line-height: 1.45;
    }

    .LotsAuction .product-view-right .pv-rating-wrap {
        margin: 14px auto;
        flex-wrap: wrap;
    }

    .LotsAuction .product-view-wrapper .MuiTabs-flexContainer {
        overflow: scroll;
    }

    .LotsAuction .product-view-right .productStatus h4 {
        margin: 10px auto;
        height: auto;
        font-size: 12px;
        padding: 5px 0;
    }
}

@media print {
    .LotsAuction .similar-items-container {
        display: none !important;
    }
}

@media (max-width: 479px) {
    .pvr-top-right .favoriteCheck {
        margin-left: 0.5rem !important;
    }

    .LotsAuction .share-menu button,
    .LotsAuction .pvr-top-right a,
    .LotsAuction .pvr-top-right .fav-wrap {
        font-size: 12px;
    }

    .LotsAuction .product-view-right .plTitle {
        font-size: 18px;
    }
}
